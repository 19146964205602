import { CustomArrowProps } from "react-slick";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

//Wrapping react-slick arrows to fix the issue with missing currentSlide and slideCount props
//Warning: Unknown props `currentSlide`, `slideCount` on <div> tag. Remove these props from the element.
//https://github.com/akiran/react-slick/issues/623#issuecomment-629764816
export const NextArrow = ({
  currentSlide,
  slideCount,
  lang,
  ...props
}: CustomArrowProps & { lang: string }) => (
  <div {...props}>
    {lang === "ar" ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
  </div>
);

export const PrevArrow = ({
  currentSlide,
  slideCount,
  lang,
  ...props
}: CustomArrowProps & { lang: string }) => (
  <div {...props}>
    {lang === "ar" ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />}
  </div>
);
