"use client";

import dynamic from 'next/dynamic';

const Timer = dynamic(() => import('./timer'), { ssr: false });

const ClientTimer = ({
  targetDate,
  dark,
  short,
}: {
  targetDate: string | number | Date;
  dark?: boolean;
  short?: boolean;
}) => {
  return <Timer targetDate={targetDate} dark={dark} short={short} />;
};

export default ClientTimer;