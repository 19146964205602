import axiosClient from "./base";

const USER_LOGIN= "/app/auth/login";

type LoginProps = {
  email: string;
  password: string
};

const login = ({ password, email }: LoginProps) =>
  axiosClient.post(USER_LOGIN, { password, email });

export { login};