"use client";

import { Box, Button } from "@mui/material";
import React, { useState } from "react";

export default function PopularProductsTaps() {
  const [categoryType, setCategoryType] = useState("feature");

  return (
    <Box sx={{ display: "flex", gap: "40px", my: "40px", pb: { lg: "16px"} }}>
      <Button
        sx={{
          height: "48px",
          borderRadius: "unset",
          borderBottom:
            categoryType === "featured" ? "2px solid #1C252E" : "unset",
          cursor: "pointer",
          display: "flex",
          justifyContent: "center",
          textTransform: "capitalize",
          fontSize: "14px",
          color: categoryType === "featured" ? "#1C252E" : "#637381",
          "&:hover": {
            backgroundColor: "transparent",
          },
        }}
        onClick={() => setCategoryType("featured")}
      >
        Featured
      </Button>

      <Button
        sx={{
          height: "48px",
          borderRadius: "unset",
          borderBottom:
            categoryType === "top-rated" ? "2px solid #1C252E" : "unset",
          cursor: "pointer",
          display: "flex",
          justifyContent: "center",
          textTransform: "capitalize",
          fontSize: "14px",
          color: categoryType === "top-rated" ? "#1C252E" : "#637381",
          "&:hover": {
            backgroundColor: "transparent",
          },
        }}
        onClick={() => setCategoryType("top-rated")}
      >
        Top rated
      </Button>

      <Button
        sx={{
          height: "48px",
          borderRadius: "unset",
          borderBottom:
            categoryType === "onSale" ? "2px solid #1C252E" : "unset",
          cursor: "pointer",
          display: "flex",
          justifyContent: "center",
          textTransform: "capitalize",
          fontSize: "14px",
          color: categoryType === "onSale" ? "#1C252E" : "#637381",
          "&:hover": {
            backgroundColor: "transparent",
          },
        }}
        onClick={() => setCategoryType("onSale")}
      >
        Onsale
      </Button>
    </Box>
  );
}
