import Image from "next/image";
import {
  isImageFitCover,
  isImageSlide,
  useLightboxProps,
  useLightboxState,
} from "yet-another-react-lightbox";

type SlideProps = {
  slide?: {
    src: string;
    width?: number;
    height?: number;
    blurDataURL?: string;
  };
  offset?: number;
  rect?: {
    width: number;
    height: number;
  };
};

function isNextJsImage(slide: SlideProps['slide']) {
  return (
    slide && isImageSlide(slide) &&
    typeof slide.width === "number" &&
    typeof slide.height === "number"
  );
}

export default function NextJsImage({ slide, offset, rect }: SlideProps) {
  const {
    on: { click },
    carousel: { imageFit },
  } = useLightboxProps();

  const { currentIndex } = useLightboxState();

  const cover = slide && isImageSlide(slide) && isImageFitCover(slide, imageFit);

  if (!slide || !isNextJsImage(slide)) return null;

  const width = !cover
    ? Math.round(
        Math.min(rect?.width ?? 0, ((rect?.height ?? 0) / (slide.height ?? 1)) * (slide.width ?? 1)),
      )
    : rect?.width ?? 0;

  const height = !cover
    ? Math.round(
        Math.min(rect?.height ?? 0, ((rect?.width ?? 0) / (slide.width ?? 1)) * (slide.height ?? 1)),
      )
    : rect?.height ?? 0;

  return (
    <div style={{ position: "relative", width, height }}>
      <Image
        fill
        alt=""
        src={slide.src}
        loading="eager"
        draggable={false}
        placeholder={slide.blurDataURL ? "blur" : undefined}
        style={{
          objectFit: cover ? "cover" : "contain",
          cursor: click ? "pointer" : undefined,
        }}
        sizes={`${Math.ceil((width / window.innerWidth) * 100)}vw`}
        onClick={
          offset === 0 ? () => click?.({ index: currentIndex }) : undefined
        }
      />
    </div>
  );

}