'use client'

import { useEffect, useState } from 'react'
import i18next from 'i18next'
import { initReactI18next, useTranslation as useTranslationOrg } from 'react-i18next'
import resourcesToBackend from 'i18next-resources-to-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { getOptions, languages, cookieName } from './settings'

const runsOnServerSide = typeof window === 'undefined'

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(resourcesToBackend((language: string, namespace: string) => import(`./locales/${language}/${namespace}.json`)))
  .init({
    ...getOptions(),
    lng: undefined, // let detect the language on client side
    detection: {
      order: ['cookie'],
      lookupCookie: cookieName,
      cookieOptions: {
        path: '/'
      }
    },
    preload: runsOnServerSide ? languages : []
  })

export function useTranslation(lng: string, ns: string, options: {}) {
  const ret = useTranslationOrg(ns, options)
  const { i18n } = ret

  // console.log('lng', lng)
  // console.log('i18n.language', i18n.language)
  // console.log('i18n.languages', i18n.languages)
  // console.log('i18n.isInitialized', i18n.isInitialized)
  // console.log('i18n.isInitializing', i18n.isInitializing)
  // console.log('i18n.resolvedLanguage', i18n.resolvedLanguage)


  // useEffect(() => {
  //   if (!lng || i18n.resolvedLanguage === lng) return
  //   i18n.changeLanguage(lng)
  // }, [lng, i18n.resolvedLanguage, i18n])

  if (runsOnServerSide && lng && i18n.resolvedLanguage !== lng) {
    i18n.changeLanguage(lng)
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    // const [activeLng, setActiveLng] = useState(i18n.resolvedLanguage)
    // eslint-disable-next-line react-hooks/rules-of-hooks
    // useEffect(() => {
    //   if (activeLng === i18n.resolvedLanguage) return
    //   setActiveLng(i18n.resolvedLanguage)
    // }, [activeLng, i18n.resolvedLanguage])
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (!lng || i18n.resolvedLanguage === lng) return
      i18n.changeLanguage(lng)
    }, [lng, i18n])
  }

  return ret
}

export default i18next