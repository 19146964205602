import { BASE_URL } from "@/utils/configs";
import {  getValueFromLocalStorage } from "@/utils/localStorage";
import { getSubdomain } from "@/utils/subdomain";
import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

let baseUrl = ''

 const getBaseUrl = () => {
  if (baseUrl) return baseUrl
  const protocol = BASE_URL.includes('localhost') ? 'http' : 'https';
  baseUrl = `${protocol}://${BASE_URL}`;
  return baseUrl
 }


const axiosClient = axios.create({
  baseURL: getBaseUrl(),
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    'x-matjr-referer': typeof window !== 'undefined' && window?.location?.host
  },
});

axiosClient.interceptors.request.use(async (config) => {
  const user = getValueFromLocalStorage('user');
  
  if (user) {
    const accessToken = user?.token
    config.headers.Authorization = `Bearer ${accessToken}`;

  }

  return config;
});

axiosClient.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export const axiosFetcher = async (
  args: string | [string, AxiosRequestConfig]
) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosClient.get(url, { ...config });

  return res.data;
};

let axiosClientWithBaseURL: AxiosInstance;

export const axiosClientWithAuthHeader = () => {
  if (axiosClientWithBaseURL) return axiosClientWithBaseURL;

  axiosClientWithBaseURL = axios.create({
    baseURL: getBaseUrl(),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  axiosClientWithBaseURL.interceptors.request.use(async (config) => {
    const user = getValueFromLocalStorage('user');
    if (user) {
      const accessToken = user?.token
      config.headers.Authorization = `Bearer ${accessToken}`;
  
    }
    return config;
  });

  axiosClientWithBaseURL.interceptors.response.use(
    (res) => res,
    (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
  );

  return axiosClientWithBaseURL;
}

export const axiosFetcherWithCustomURL = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosClientWithAuthHeader().get(url, { ...config });

  return res.data;
}

export default axiosClient;
