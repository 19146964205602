"use client";

import { Box, Button, Container, Typography } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./hero.css";
import Image from "next/legacy/image";

const settings = (lang: string) => ({
  dots: true,
  fade: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  waitForAnimate: false,
  autoplay: true,
  autoplaySpeed: 2500,
  cssEase: "fade",
  rtl: lang === "ar" ? true : false,
  nextArrow: <ArrowForwardIosIcon />,
  prevArrow: <ArrowBackIosIcon />,
  customPaging: () => (
    <Box
      sx={{
        background: "#fa541c",
        width: "8px",
        height: "8px",
        borderRadius: "50%",
        opacity: "0.6",
      }}
    />
  ),
});

export default function HeroSlider({ lang }: { lang: string }) {
  return (
    <Slider {...settings(lang)} className="fashion-slider">
      <Box>
        <Container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            py: "100px",
            flexDirection: { xs: "column", md: "row" },
            gap: { xs: "60px", md: "0" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: { xs: "100%", lg: "50%" },
              textAlign: { xs: "center", lg: "unset" },
              alignItems: { xs: "center", lg: "unset" },
              justifyContent: { xs: "center", lg: "unset" },
            }}
          >
            <Typography
              sx={{
                width: "171px",
                height: "24px",
                px: "6px",
                fontSize: "12px",
                fontWeight: "700",
                mb: "16px",
                color: "#1C252E",
                backgroundColor: "#FFAB00",
                borderRadius: "6px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Opening Sale Discount 50%
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "32px", lg: "48px" },
                fontWeight: "800",
                mb: "16px",
                color: "#fff",
                width: { lg: "80%" },
              }}
            >
              Urban Explorer Sneakers
            </Typography>
            <Typography
              className="block-text text-2"
              sx={{
                fontSize: "14px",
                mb: "40px",
                color: "#fff",
                width: { lg: "70%" },
              }}
            >
              Occaecati est et illo quibusdam accusamus qui. Incidunt aut et
              molestiae ut facere aut. Est quidem iusto praesentium excepturi
              harum nihil tenetur facilis. Ut omnis voluptates nihil accusantium
              doloribus eaque debitis.
            </Typography>
            <Button
              sx={{
                width: "140px",
                height: "48px",
                p: "8px 16px",
                borderRadius: "8px",
                backgroundColor: "#FA541C",
                color: "#fff",
                fontSize: "15px",
                fontWeight: "700",
                textTransform: "unset",
                "&:hover": {
                  backgroundColor: "#FA541C",
                },
              }}
            >
              Shop now
              <KeyboardArrowRightIcon sx={{ ml: "8px" }} />
            </Button>
          </Box>

          <Box
            sx={{
              position: "relative",
              width: { xs: "343px", lg: "50%" },
              height: { xs: "343px", lg: "480px" },
              mb: { xs: "60px", lg: "0" },
            }}
          >
            <Image
              src={"/images/product-1.webp"}
              layout="fill"
              objectFit="cover"
              alt="hero-img"
            />
          </Box>
        </Container>
      </Box>

      <Box>
        <Container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            py: "100px",
            flexDirection: { xs: "column", md: "row" },
            gap: { xs: "60px", md: "0" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: { xs: "100%", lg: "50%" },
              textAlign: { xs: "center", lg: "unset" },
              alignItems: { xs: "center", lg: "unset" },
              justifyContent: { xs: "center", lg: "unset" },
            }}
          >
            <Typography
              sx={{
                width: "171px",
                height: "24px",
                px: "6px",
                fontSize: "12px",
                fontWeight: "700",
                mb: "16px",
                color: "#1C252E",
                backgroundColor: "#FFAB00",
                borderRadius: "6px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Opening Sale Discount 50%
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "32px", lg: "48px" },
                fontWeight: "800",
                mb: "16px",
                color: "#fff",
                width: { lg: "80%" },
              }}
            >
              Classic Leather Loafers
            </Typography>
            <Typography
              className="block-text text-2"
              sx={{
                fontSize: "14px",
                mb: "40px",
                color: "#fff",
                width: { lg: "70%" },
              }}
            >
              Occaecati est et illo quibusdam accusamus qui. Incidunt aut et
              molestiae ut facere aut. Est quidem iusto praesentium excepturi
              harum nihil tenetur facilis. Ut omnis voluptates nihil accusantium
              doloribus eaque debitis.
            </Typography>
            <Button
              sx={{
                width: "140px",
                height: "48px",
                p: "8px 16px",
                borderRadius: "8px",
                backgroundColor: "#FA541C",
                color: "#fff",
                fontSize: "15px",
                fontWeight: "700",
                textTransform: "unset",
                "&:hover": {
                  backgroundColor: "#FA541C",
                },
              }}
            >
              Shop now
              <KeyboardArrowRightIcon sx={{ ml: "8px" }} />
            </Button>
          </Box>

          <Box
            sx={{
              position: "relative",
              width: { xs: "343px", lg: "50%" },
              height: { xs: "343px", lg: "480px" },
              mb: { xs: "60px", lg: "0" },
            }}
          >
            <Image
              src={"/images/product-2.webp"}
              layout="fill"
              objectFit="cover"
              alt="hero-img"
            />
          </Box>
        </Container>
      </Box>

      <Box>
        <Container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            py: "100px",
            flexDirection: { xs: "column", md: "row" },
            gap: { xs: "60px", md: "0" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: { xs: "100%", lg: "50%" },
              textAlign: { xs: "center", lg: "unset" },
              alignItems: { xs: "center", lg: "unset" },
              justifyContent: { xs: "center", lg: "unset" },
            }}
          >
            <Typography
              sx={{
                width: "171px",
                height: "24px",
                px: "6px",
                fontSize: "12px",
                fontWeight: "700",
                mb: "16px",
                color: "#1C252E",
                backgroundColor: "#FFAB00",
                borderRadius: "6px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Opening Sale Discount 50%
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "32px", lg: "48px" },
                fontWeight: "800",
                mb: "16px",
                color: "#fff",
                width: { lg: "80%" },
              }}
            >
              Mountain Trekking Boots
            </Typography>
            <Typography
              className="block-text text-2"
              sx={{
                fontSize: "14px",
                mb: "40px",
                color: "#fff",
                width: { lg: "70%" },
              }}
            >
              Rerum eius velit dolores. Explicabo ad nemo quibusdam. Voluptatem
              eum suscipit et ipsum et consequatur aperiam quia. Rerum nulla
              sequi recusandae illum velit quia quas. Et error laborum maiores
              cupiditate occaecati.
            </Typography>
            <Button
              sx={{
                width: "140px",
                height: "48px",
                p: "8px 16px",
                borderRadius: "8px",
                backgroundColor: "#FA541C",
                color: "#fff",
                fontSize: "15px",
                fontWeight: "700",
                textTransform: "unset",
                "&:hover": {
                  backgroundColor: "#FA541C",
                },
              }}
            >
              Shop now
              <KeyboardArrowRightIcon sx={{ ml: "8px" }} />
            </Button>
          </Box>

          <Box
            sx={{
              position: "relative",
              width: { xs: "343px", lg: "50%" },
              height: { xs: "343px", lg: "480px" },
              mb: { xs: "60px", lg: "0" },
            }}
          >
            <Image
              src={"/images/product-3.webp"}
              layout="fill"
              objectFit="cover"
              alt="hero-img"
            />
          </Box>
        </Container>
      </Box>

      <Box>
        <Container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            py: "100px",
            flexDirection: { xs: "column", md: "row" },
            gap: { xs: "60px", md: "0" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: { xs: "100%", lg: "50%" },
              textAlign: { xs: "center", lg: "unset" },
              alignItems: { xs: "center", lg: "unset" },
              justifyContent: { xs: "center", lg: "unset" },
            }}
          >
            <Typography
              sx={{
                width: "171px",
                height: "24px",
                px: "6px",
                fontSize: "12px",
                fontWeight: "700",
                mb: "16px",
                color: "#1C252E",
                backgroundColor: "#FFAB00",
                borderRadius: "6px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Opening Sale Discount 50%
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "32px", lg: "48px" },
                fontWeight: "800",
                mb: "16px",
                color: "#fff",
                width: { lg: "80%" },
              }}
            >
              Mountain Trekking Boots
            </Typography>
            <Typography
              className="block-text text-2"
              sx={{
                fontSize: "14px",
                mb: "40px",
                color: "#fff",
                width: { lg: "70%" },
              }}
            >
              Rerum eius velit dolores. Explicabo ad nemo quibusdam. Voluptatem
              eum suscipit et ipsum et consequatur aperiam quia. Rerum nulla
              sequi recusandae illum velit quia quas. Et error laborum maiores
              cupiditate occaecati.
            </Typography>
            <Button
              sx={{
                width: "140px",
                height: "48px",
                p: "8px 16px",
                borderRadius: "8px",
                backgroundColor: "#FA541C",
                color: "#fff",
                fontSize: "15px",
                fontWeight: "700",
                textTransform: "unset",
                "&:hover": {
                  backgroundColor: "#FA541C",
                },
              }}
            >
              Shop now
              <KeyboardArrowRightIcon sx={{ ml: "8px" }} />
            </Button>
          </Box>

          <Box
            sx={{
              position: "relative",
              width: { xs: "343px", lg: "50%" },
              height: { xs: "343px", lg: "480px" },
              mb: { xs: "60px", lg: "0" },
            }}
          >
            <Image
              src={"/images/product-3.webp"}
              layout="fill"
              objectFit="cover"
              alt="hero-img"
            />
          </Box>
        </Container>
      </Box>

      <Box>
        <Container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            py: "100px",
            flexDirection: { xs: "column", md: "row" },
            gap: { xs: "60px", md: "0" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: { xs: "100%", lg: "50%" },
              textAlign: { xs: "center", lg: "unset" },
              alignItems: { xs: "center", lg: "unset" },
              justifyContent: { xs: "center", lg: "unset" },
            }}
          >
            <Typography
              sx={{
                width: "171px",
                height: "24px",
                px: "6px",
                fontSize: "12px",
                fontWeight: "700",
                mb: "16px",
                color: "#1C252E",
                backgroundColor: "#FFAB00",
                borderRadius: "6px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Opening Sale Discount 50%
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "32px", lg: "48px" },
                fontWeight: "800",
                mb: "16px",
                color: "#fff",
                width: { lg: "80%" },
              }}
            >
              Elegance Stiletto Heels
            </Typography>
            <Typography
              className="block-text text-2"
              sx={{
                fontSize: "14px",
                mb: "40px",
                color: "#fff",
                width: { lg: "70%" },
              }}
            >
              Et non omnis qui. Qui sunt deserunt dolorem aut velit cumque
              adipisci aut enim. Nihil quis quisquam nesciunt dicta nobis ab
              aperiam dolorem repellat. Voluptates non blanditiis. Error et
              tenetur iste soluta cupiditate ratione perspiciatis et. Quibusdam
              aliquid nam sunt et quisquam non esse.
            </Typography>
            <Button
              sx={{
                width: "140px",
                height: "48px",
                p: "8px 16px",
                borderRadius: "8px",
                backgroundColor: "#FA541C",
                color: "#fff",
                fontSize: "15px",
                fontWeight: "700",
                textTransform: "unset",
                "&:hover": {
                  backgroundColor: "#FA541C",
                },
              }}
            >
              Shop now
              <KeyboardArrowRightIcon sx={{ ml: "8px" }} />
            </Button>
          </Box>

          <Box
            sx={{
              position: "relative",
              width: { xs: "343px", lg: "50%" },
              height: { xs: "343px", lg: "480px" },
              mb: { xs: "60px", lg: "0" },
            }}
          >
            <Image
              src={"/images/product-4.webp"}
              layout="fill"
              objectFit="cover"
              alt="hero-img"
            />
          </Box>
        </Container>
      </Box>
    </Slider>
  );
}
