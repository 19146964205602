"use client";

import { Box, Typography } from "@mui/material";

import MuiSlider from "@mui/material/Slider";
import React, { useEffect, useRef } from "react";
import Image from "next/legacy/image";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css";

const settings = (lang: string) => ({
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 1,
  nextArrow: lang === "ar" ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />,
  prevArrow: lang === "ar" ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />,
  rtl: true,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
});

export default function DealTodaySlider({ lang }: { lang: string }) {
  const sliderRef = useRef(null);
  useEffect(() => {
    if (sliderRef.current) {
      (sliderRef.current as any).slickGoTo(0);
    }
  }, []);
  return (
    <Slider {...settings(lang)} ref={sliderRef} className="deal-today-slider">
      <Box
        sx={{
          p: "16px",
          borderRadius: "16px",
          border: "1px solid #919eab1f",
        }}
      >
        <Box
          sx={{
            width: { xs: '100%', lg: "138px"},
            height: "138px",
            backgroundColor: "#f4f6f8",
            mb: "16px",
            borderRadius: "12px",
            position: "relative",
          }}
        >
          <Image
            src={"/images/product-1.webp"}
            alt=""
            layout="fill"
            objectFit="cover"
          />
        </Box>
        <Typography
          className="block-text"
          sx={{
            fontSize: "14px",
            color: "#1C252E",
            fontWeight: "500",
            mb: "4px",
          }}
        >
          Apple iPhone
        </Typography>
        <Typography
          className="block-text"
          sx={{ fontSize: "14px", color: "#FF5630", fontWeight: "600" }}
        >
          $83.74
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            mt: "8px",
          }}
        >
          <MuiSlider
            aria-label="Temperature"
            defaultValue={100}
            color="secondary"
            sx={{
              width: { xs: '50%', lg: "68px"},
              "& .MuiSlider-thumb ": { width: "0px", height: "0px" },
            }}
          />
          <Typography
            sx={{ fontSize: "12px", color: "#919EAB", fontWeight: "400", width: { xs: '50%', lg: "unset" } }}
          >
            🔥 100 sold
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          p: "16px",
          borderRadius: "16px",
          border: "1px solid #919eab1f",
        }}
      >
        <Box
          sx={{
            width: { xs: '100%', lg: "138px"},
            height: "138px",
            backgroundColor: "#f4f6f8",
            mb: "16px",
            borderRadius: "12px",
            position: "relative",
          }}
        >
          <Image
            src={"/images/product-2.webp"}
            alt=""
            layout="fill"
            objectFit="cover"
          />
        </Box>
        <Typography
          className="block-text"
          sx={{
            fontSize: "14px",
            color: "#1C252E",
            fontWeight: "500",
            mb: "4px",
          }}
        >
          Samsung Galaxy
        </Typography>
        <Typography
          className="block-text"
          sx={{ fontSize: "14px", color: "#FF5630", fontWeight: "600" }}
        >
          $97.14
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            mt: "8px",
          }}
        >
          <MuiSlider
            aria-label="Temperature"
            defaultValue={50}
            color="secondary"
            sx={{
              width: { xs: '50%', lg: "68px"},
              "& .MuiSlider-thumb ": { width: "0px", height: "0px" },
            }}
          />
          <Typography
            sx={{ fontSize: "12px", color: "#919EAB", fontWeight: "400", width: { xs: '50%', lg: "unset" } }}
          >
            🔥 50 sold
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          p: "16px",
          borderRadius: "16px",
          border: "1px solid #919eab1f",
        }}
      >
        <Box
          sx={{
            width: { xs: '100%', lg: "138px"},
            height: "138px",
            backgroundColor: "#f4f6f8",
            mb: "16px",
            borderRadius: "12px",
            position: "relative",
          }}
        >
          <Image
            src={"/images/product-3.webp"}
            alt=""
            layout="fill"
            objectFit="cover"
          />
        </Box>
        <Typography
          className="block-text"
          sx={{
            fontSize: "14px",
            color: "#1C252E",
            fontWeight: "500",
            mb: "4px",
          }}
        >
          Nike Air Max
        </Typography>
        <Typography
          className="block-text"
          sx={{ fontSize: "14px", color: "#FF5630", fontWeight: "600" }}
        >
          $68.71
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            mt: "8px",
          }}
        >
          <MuiSlider
            aria-label="Temperature"
            defaultValue={33}
            color="secondary"
            sx={{
              width: { xs: '50%', lg: "68px"},
              "& .MuiSlider-thumb ": { width: "0px", height: "0px" },
            }}
          />
          <Typography
            sx={{ fontSize: "12px", color: "#919EAB", fontWeight: "400", width: { xs: '50%', lg: "unset" } }}
          >
            🔥 33 sold
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          p: "16px",
          borderRadius: "16px",
          border: "1px solid #919eab1f",
        }}
      >
        <Box
          sx={{
            width: { xs: '100%', lg: "138px"},
            height: "138px",
            backgroundColor: "#f4f6f8",
            mb: "16px",
            borderRadius: "12px",
            position: "relative",
          }}
        >
          <Image
            src={"/images/product-4.webp"}
            alt=""
            layout="fill"
            objectFit="cover"
          />
        </Box>
        <Typography
          className="block-text"
          sx={{
            fontSize: "14px",
            color: "#1C252E",
            fontWeight: "500",
            mb: "4px",
          }}
        >
          Adidas Ultraboost
        </Typography>
        <Typography
          className="block-text"
          sx={{ fontSize: "14px", color: "#FF5630", fontWeight: "600" }}
        >
          $85.21
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            mt: "8px",
          }}
        >
          <MuiSlider
            aria-label="Temperature"
            defaultValue={25}
            color="secondary"
            sx={{
              width: { xs: '50%', lg: "68px"},
              "& .MuiSlider-thumb ": { width: "0px", height: "0px" },
            }}
          />
          <Typography
            sx={{ fontSize: "12px", color: "#919EAB", fontWeight: "400", width: { xs: '50%', lg: "unset" } }}
          >
            🔥 25 sold
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          p: "16px",
          borderRadius: "16px",
          border: "1px solid #919eab1f",
        }}
      >
        <Box
          sx={{
            width: { xs: '100%', lg: "138px"},
            height: "138px",
            backgroundColor: "#f4f6f8",
            mb: "16px",
            borderRadius: "12px",
            position: "relative",
          }}
        >
          <Image
            src={"/images/product-5.webp"}
            alt=""
            layout="fill"
            objectFit="cover"
          />
        </Box>
        <Typography
          className="block-text"
          sx={{
            fontSize: "14px",
            color: "#1C252E",
            fontWeight: "500",
            mb: "4px",
          }}
        >
          Sony PlayStation
        </Typography>
        <Typography
          className="block-text"
          sx={{ fontSize: "14px", color: "#FF5630", fontWeight: "600" }}
        >
          $52.17
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            mt: "8px",
          }}
        >
          <MuiSlider
            aria-label="Temperature"
            defaultValue={20}
            color="secondary"
            sx={{
              width: { xs: '50%', lg: "68px"},
              "& .MuiSlider-thumb ": { width: "0px", height: "0px" },
            }}
          />
          <Typography
            sx={{ fontSize: "12px", color: "#919EAB", fontWeight: "400", width: { xs: '50%', lg: "unset" } }}
          >
            🔥 20 sold
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          p: "16px",
          borderRadius: "16px",
          border: "1px solid #919eab1f",
        }}
      >
        <Box
          sx={{
            width: { xs: '100%', lg: "138px"},
            height: "138px",
            backgroundColor: "#f4f6f8",
            mb: "16px",
            borderRadius: "12px",
            position: "relative",
          }}
        >
          <Image
            src={"/images/product-6.webp"}
            alt=""
            layout="fill"
            objectFit="cover"
          />
        </Box>
        <Typography
          className="block-text"
          sx={{
            fontSize: "14px",
            color: "#1C252E",
            fontWeight: "500",
            mb: "4px",
          }}
        >
          Microsoft Surface
        </Typography>
        <Typography
          className="block-text"
          sx={{ fontSize: "14px", color: "#FF5630", fontWeight: "600" }}
        >
          $25.18
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            mt: "8px",
          }}
        >
          <MuiSlider
            aria-label="Temperature"
            defaultValue={17}
            color="secondary"
            sx={{
              width: { xs: '50%', lg: "68px"},
              "& .MuiSlider-thumb ": { width: "0px", height: "0px" },
            }}
          />
          <Typography
            sx={{ fontSize: "12px", color: "#919EAB", fontWeight: "400", width: { xs: '50%', lg: "unset" } }}
          >
            🔥 17 sold
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          p: "16px",
          borderRadius: "16px",
          border: "1px solid #919eab1f",
        }}
      >
        <Box
          sx={{
            width: { xs: '100%', lg: "138px"},
            height: "138px",
            backgroundColor: "#f4f6f8",
            mb: "16px",
            borderRadius: "12px",
            position: "relative",
          }}
        >
          <Image
            src={"/images/product-7.webp"}
            alt=""
            layout="fill"
            objectFit="cover"
          />
        </Box>
        <Typography
          className="block-text"
          sx={{
            fontSize: "14px",
            color: "#1C252E",
            fontWeight: "500",
            mb: "4px",
          }}
        >
          Tesla Model S
        </Typography>
        <Typography
          className="block-text"
          sx={{ fontSize: "14px", color: "#FF5630", fontWeight: "600" }}
        >
          $43.84
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            mt: "8px",
          }}
        >
          <MuiSlider
            aria-label="Temperature"
            defaultValue={11}
            color="secondary"
            sx={{
              width: { xs: '50%', lg: "68px"},
              "& .MuiSlider-thumb ": { width: "0px", height: "0px" },
            }}
          />
          <Typography
            sx={{ fontSize: "12px", color: "#919EAB", fontWeight: "400", width: { xs: '50%', lg: "unset" } }}
          >
            🔥 11 sold
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          p: "16px",
          borderRadius: "16px",
          border: "1px solid #919eab1f",
        }}
      >
        <Box
          sx={{
            width: { xs: '100%', lg: "138px"},
            height: "138px",
            backgroundColor: "#f4f6f8",
            mb: "16px",
            borderRadius: "12px",
            position: "relative",
          }}
        >
          <Image
            src={"/images/product-1.webp"}
            alt=""
            layout="fill"
            objectFit="cover"
          />
        </Box>
        <Typography
          className="block-text"
          sx={{
            fontSize: "14px",
            color: "#1C252E",
            fontWeight: "500",
            mb: "4px",
          }}
        >
          Apple iPhone
        </Typography>
        <Typography
          className="block-text"
          sx={{ fontSize: "14px", color: "#FF5630", fontWeight: "600" }}
        >
          $83.74
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            mt: "8px",
          }}
        >
          <MuiSlider
            aria-label="Temperature"
            defaultValue={100}
            color="secondary"
            sx={{
              width: { xs: '50%', lg: "68px"},
              "& .MuiSlider-thumb ": { width: "0px", height: "0px" },
            }}
          />
          <Typography
            sx={{ fontSize: "12px", color: "#919EAB", fontWeight: "400", width: { xs: '50%', lg: "unset" } }}
          >
            🔥 100 sold
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          p: "16px",
          borderRadius: "16px",
          border: "1px solid #919eab1f",
        }}
      >
        <Box
          sx={{
            width:{ xs: '100%', lg: "138px"},
            height: "138px",
            backgroundColor: "#f4f6f8",
            mb: "16px",
            borderRadius: "12px",
            position: "relative",
          }}
        >
          <Image
            src={"/images/product-8.webp"}
            alt=""
            layout="fill"
            objectFit="cover"
          />
        </Box>
        <Typography
          className="block-text"
          sx={{
            fontSize: "14px",
            color: "#1C252E",
            fontWeight: "500",
            mb: "4px",
          }}
        >
          Amazon Echo
        </Typography>
        <Typography
          className="block-text"
          sx={{ fontSize: "14px", color: "#FF5630", fontWeight: "600" }}
        >
          $60.98
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            mt: "8px",
          }}
        >
          <MuiSlider
            aria-label="Temperature"
            defaultValue={13}
            color="secondary"
            sx={{
              width: { xs: '50%', lg: "68px"},
              "& .MuiSlider-thumb ": { width: "0px", height: "0px" },
            }}
          />
          <Typography
            sx={{ fontSize: "12px", color: "#919EAB", fontWeight: "400", width: { xs: '50%', lg: "unset" } }}
          >
            🔥 13 sold
          </Typography>
        </Box>
      </Box>
    </Slider>
  );
}
