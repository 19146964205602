"use client";

import { Avatar, Box, Typography } from "@mui/material";
import React from "react";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import LoginIcon from "@mui/icons-material/Login";
// import { useTranslation } from "react-i18next";
import DehazeIcon from "@mui/icons-material/Dehaze";
import Image from "next/image";
import AppBarSearch from "./appbar-search";
import { useRouter, usePathname } from "next/navigation";
import { useAuth } from "@/context/auth";
import { useCartDrawer } from "@/context/cart";
import { Company } from "@/type/company";
import LangSwitcher from "./fashion-app/lang-switcher";
import Link from "next/link";
import { useTranslation } from "@/app/i18n/client";

type AppBarProps = {
  handleDrawerOpen: () => void;
  company: Company;
  lang: string;
  showSearch: boolean;
};

export default function AppBar({
  handleDrawerOpen,
  company,
  lang,
  showSearch = true,
}: AppBarProps) {
  const { t } = useTranslation(lang, "translation", {});

  const { user } = useAuth();

  const { enableLogin } = company;

  const { openDrawer, cartItemsCount } = useCartDrawer();

  const router = useRouter();

  const pathname = usePathname();
  const isLoginPage = pathname === "/login" || pathname === "/signup";

  return (
    <Box
      sx={{
        pl: { xs: "16px", md: "26px" },
        pr: { xs: "22px", md: "40px" },
        py: { xs: "20px", md: "14px" },
        display: "grid",
        justifyContent: "space-between",
        alignItems: "center",
        gridTemplateColumns: { xs: "repeat(3, 1fr)", lg: "repeat(3, auto)" },
        gridTemplateRows: "auto",
        gridTemplateAreas: {
          lg: `"logo search icons" `,
          xs: `"logo logo icons" 
               "search search search"`,
        },
      }}
    >
      <Box
        sx={{
          // width: "10%",
          gridArea: "logo",
          display: "flex",
          alignItems: "center",
          gap: { xs: "8px", lg: "17px" },
        }}
      >
        <Link href="/" style={{ cursor: "pointer", textDecoration: "none" }}>
          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <Image
              src={company?.logo || "/images/logo.png"}
              alt=""
              width={26}
              height={26}
              sizes="(max-width: 768px) 18px, 22px"
            />
            <Typography
              sx={{
                fontWeight: "800",
                fontSize: "22px",
                color: "primary.main",
                marginInlineStart: "8px",
              }}
            >
              {company?.name}
            </Typography>
          </Box>
        </Link>
      </Box>
      {showSearch ? <AppBarSearch lang={lang} /> : null}

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          gap: { xs: "16px", lg: "20px" },
          color: "rgba(0, 0, 0, 0.6)",
          gridArea: "icons",
        }}
      >
        {!company?.settings?.hideLangSwitcher && (
          <LangSwitcher lang={lang} company={company} />
        )}
        {company?.settings?.showFavoritesPage && <FavoriteBorderIcon />}

        <Box position={"relative"}>
          {!!cartItemsCount && (
            <Box
              sx={{
                position: "absolute",
                top: "-10px",
                right: lang === "en" ? "-10px" : "10px",
                minWidth: "20px",
                height: "20px",
                width: "max-content",
                borderRadius: "50%",
                color: "white",
                fontSize: "14px",
                backgroundColor: "primary.main",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {cartItemsCount}
            </Box>
          )}

          <ShoppingCartIcon
            sx={{ cursor: "pointer" }}
            onClick={() => openDrawer(true)}
          />
        </Box>

        <DehazeIcon
          onClick={handleDrawerOpen}
          sx={{ display: { xs: "block", lg: "none" }, cursor: "pointer" }}
        />

        {user && (
          <Box sx={{ display: { xs: "none", lg: "flex" } }}>
            <Avatar
              src="images/user-profile.png"
              sx={{ width: "32px", height: "32px", cursor: "pointer" }}
              onClick={() => router.push("/account")}
            />
          </Box>
        )}

        {enableLogin && !user && !isLoginPage && (
          <Box
            sx={{
              color: "primary.main",
              display: { xs: "none", lg: "flex" },
              alignItems: "center",
              gap: "2px",
              cursor: "pointer",
            }}
            onClick={() => router.push("/login")}
          >
            <LoginIcon />
            <Typography sx={{ fontWeight: "700", fontSize: "14px" }}>
              {t("login")}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}
