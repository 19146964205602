/* eslint-disable @next/next/no-img-element */
"use client";
import React from "react";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import StarOutlineRoundedIcon from "@mui/icons-material/StarOutlineRounded";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "@/app/i18n/client";
import { Company } from "@/type/company";
import { ProductProps } from "@/type/product";
import Image from "next/legacy/image";
import Link from "next/link";
import { useCartDrawer } from "@/context/cart";
import { useRouter } from "next/navigation";

type ProductDiscountCardProps = {
  company: Company;
  lang: string;
  product: ProductProps;
}

export default function ProductDiscountCard({ company, lang, product }: ProductDiscountCardProps) {

  const { t } = useTranslation(lang, "product", {});

  const { addProductToCart } = useCartDrawer();

  const router = useRouter();

  return (
    <Box
      sx={{
        width: { xs: "100%", md: "246px" },
        height: { xs: "100%", lg: "412px" },
        boxShadow: "0px 0px 10px -2px rgba(130, 0, 0, 0.04)",
        borderRadius: "4px",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "16px",
          left: "16px",
          width: { xs: "84px", lg: "100px" },
          height: { xs: "28px", lg: "32px" },
          borderRadius: "30px",
          background: "rgba(253, 114, 79, 1)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{ fontWeight: "700", color: "rgba(255, 255, 255, 0.9)" }}
        >
          {t("25%-off")}
        </Typography>
      </Box>
      <Link href={`/product/${product?.slug ?? product?.productNumber}`}>
        <Box
          sx={{
            // height: { xs: "135px", lg: "234px" },
            backgroundColor: "secondaryBackground",
            p: "16px",
            pt: { lg: "29px" },
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
            // width: { xs: "135px", lg: "234px" },
            position: "relative",

            width: "100%",
            height: { xs: "160px", lg: "165px" },
          }}
        >
          <Image
            src={product?.coverUrl || "/images/device1.jpeg"}
            alt=""
            // style={{ objectFit: "contain", width: "100%", height: "100%" }}
            // layout="fill"
            // height="135"
            // width={135}
            // layout="fill"
            // objectFit="cover"
            // sizes="(max-width: 768px) 50px, 110px"
            layout="fill"
            objectFit="contain"
          />
        </Box>
      </Link>

      <Box
        sx={{
          px: "12px",
          py: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            // gap: { xs: "8px", lg: "12px" },
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "14px", lg: "16px" },
              color: "secondary.main",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              width: "150px",
              fontWeight: 400
            }}
          >
            {product?.name}
          </Typography>
          {/*TODO: remove this section when rating functionality is implemented*/}
          <Typography
            sx={{
              fontSize: { xs: "12px", lg: "14px" },
              color: "secondary.main",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              width: "150px",
              fontWeight: 300
            }}
          >
            {product?.subDescription}
          </Typography>
          {/*TODO: enable this section when rating functionality is implemented*/}
          {/* <Box display={"flex"} alignItems={"center"}>
            <StarRateRoundedIcon
              sx={{
                color: "rgba(234, 168, 0, 1)",
                width: "16px",
                height: "16px",
              }}
            />
            <StarRateRoundedIcon
              sx={{
                color: "rgba(234, 168, 0, 1)",
                width: "16px",
                height: "16px",
              }}
            />
            <StarRateRoundedIcon
              sx={{
                color: "rgba(234, 168, 0, 1)",
                width: "16px",
                height: "16px",
              }}
            />
            <StarOutlineRoundedIcon
              sx={{
                color: "rgba(0, 0, 0, 0.4)",
                width: "16px",
                height: "16px",
              }}
            />
            <StarOutlineRoundedIcon
              sx={{
                color: "rgba(0, 0, 0, 0.4)",
                width: "16px",
                height: "16px",
              }}
            />

            <Typography sx={{ color: "rgba(0, 0, 0, 0.6)" }}>
              {"(5)"}
            </Typography>
          </Box> */}
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", /*gap: { xs: "8px", lg: "12px" }*/ }}>
          <Typography sx={{ fontSize: "18px", fontWeight: "700", color: "secondary.main" }}>
            {`${product?.isOnSale ? product?.onSalePrice : product?.regularPrice} ${t(company?.currency || "USD")}`}
          </Typography>
          <Typography sx={{ fontSize: "12px", color: "secondary.main", textDecoration: "line-through", opacity: 0.4, display: product?.isOnSale ? "block" : "none" }} >
            {`${product?.regularPrice} ${t(company?.currency || "USD")}`}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          backgroundColor: "secondaryBackground",
          pt: "10px",
          pb: "12px",
          px: "12px",
          color: "rgba(0, 0, 0, 0.4)",
        }}
      >
        {/*TODO: enable this section when favorite functionality is implemented*/}
        {/* <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "11px",
            alignItems: "center",
          }}
        >
          <FavoriteBorderIcon />
          <Typography fontSize={"12px"}>{t("favorite")}</Typography>
        </Box> */}
        <Box
         sx={{ display: "flex", flexDirection: "column", gap: "11px", alignItems: "center", cursor: "pointer" }}
         onClick={() => { addProductToCart(product, 1, false); router.push("/cart")}}
        >
          <ShoppingBagOutlinedIcon />
          <Typography fontSize={"12px"}>{t("buy-now")}</Typography>
        </Box>
        <Box
          sx={{ display: "flex", flexDirection: "column", gap: "11px", alignItems: "center", cursor: "pointer" }}
          onClick={() => addProductToCart(product, 1)}
        >
          <AddShoppingCartIcon />
          <Typography fontSize={"12px"}>{t("add-cart")} </Typography>
        </Box>
      </Box>
    </Box>
  );
}
