import * as React from "react";
import Image from "next/legacy/image";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { Typography } from "@mui/material";
import { useTranslation } from "@/app/i18n/client";
import { useCompany } from "@/context/company";
import Link from "next/link";
import { useGetCategories } from "@/api/category";
import { Company } from "@/type/company";
import { CategoriesProps } from "@/type/category";
import { useAuth } from "@/context/auth";

type SidebarProps = {
  open: boolean;
  toggleDrawer: (open: boolean) => void;
  company: Company;
};

type CategoryDataProps = NavigationLink & Partial<CategoriesProps>;

//TODO: separate each section of app bar into separate component

export default function Sidebar({ open, toggleDrawer, company }: SidebarProps) {
  const { currentLang } = useCompany();
  const { t } = useTranslation(currentLang, "translation", {});

  const themeDirection = currentLang === "ar" ? "rtl" : "ltr";

  const { categories } = useGetCategories(company?.companyId!);

  const getCategoryUrl = (category: CategoryDataProps) => {
    return category.url
      ? category.url
      : category.slug
      ? `/products?categorySlug=${category.slug}`
      : `/products?categoryId=${category.id}`;
  };

  const { user } = useAuth();

  const { enableLogin } = company;
  const mainLinks: NavigationLink[] = [];

  if (enableLogin) {
    mainLinks.push({
      name: user ? "my-account" : "login-register",
      url: user ? "/account" : "/login",
      image: "/images/user-profile.png",
    });
  }

  mainLinks.push({ name: "main", url: "/", image: "/images/home-button.png" });

  if (company?.settings?.showDiscountsPage) {
    mainLinks.push({
      name: "offers",
      url: "/products?isOnSale=true",
      image: "/images/discount.png",
    });
  }

  if (company?.settings?.showBestSellersPage) {
    mainLinks.push({
      name: "best-sellers",
      url: "/products?isMostSold=true",
      image: "/images/quality-badge-star.png",
    });
  }

  const DrawerList = (
    <Box
      sx={{ width: 260, pl: "24px", pt: "24px" }}
      role="presentation"
      onClick={() => toggleDrawer(false)}
    >
      <Image
        src={company?.logo || "/images/logo.png"}
        alt=""
        width={48}
        height={48}
      />

      <Typography
        sx={{ mb: 3, color: "primary.main", fontSize: "22px", fontWeight: 800 }}
      >
        {company?.name}
      </Typography>

      <Box sx={{ mb: "32px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1.5,
            color: "secondary.main",
          }}
        >
          {mainLinks.map((link) => (
            <Link key={link.url} href={link.url} legacyBehavior={true}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "9px",
                  cursor: "pointer",
                  alignSelf: "flex-start",
                }}
              >
                <Image src={link.image} alt="" width={16} height={16} />
                <Typography sx={{ fontSize: "16px" }}>
                  {t(link.name)}
                </Typography>
              </Box>
            </Link>
          ))}
        </Box>
      </Box>

      <Box sx={{ mb: "32px" }}>
        <Typography
          sx={{
            mb: 2,
            color: "rgba(0, 0, 0, 0.4)",
            fontSize: "18px",
            fontWeight: 700,
          }}
        >
          {t("categories")}
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1.5,
            color: "secondary.main",
          }}
        >
          {categories.map((category) => (
            <Link
              href={getCategoryUrl(category as CategoryDataProps)}
              legacyBehavior={true}
              key={category.id}
            >
              <Box
                key={category.id}
                sx={{ cursor: "pointer", alignSelf: "flex-start" }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: "9px" }}>
                  <Image
                    src={category.image}
                    alt=""
                    width={16}
                    height={16}
                    objectFit="cover"
                  />
                  <Typography sx={{ fontSize: "16px" }}>
                    {category.enName}
                  </Typography>
                </Box>
              </Box>
            </Link>
          ))}
        </Box>
      </Box>
    </Box>
  );

  return (
    <div>
      <Drawer
        anchor={themeDirection === "ltr" ? "left" : "right"}
        open={open}
        onClose={() => toggleDrawer(false)}
        sx={{
          "& .MuiDrawer-paper": {
            right: "unset",
          },
        }}
      >
        {DrawerList}
      </Drawer>
    </div>
  );
}
