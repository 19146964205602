"use client";

import * as React from "react";
import { Box } from "@mui/material";

import Lightbox from "yet-another-react-lightbox";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
// import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";

import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/styles.css";
import NextJsImage from "./next-image";

export default function BasicLightBox({ slides }: { slides?: any }) {
  const containerRef = React.useRef(null);
  const [isLoaded, setIsLoaded] = React.useState(false);
  React.useEffect(() => {
    setIsLoaded(true);
  }, []);
  return (
    <Box
      ref={containerRef}
      id="container"
      position={"relative"}
      sx={{
        height: "100%"
      }}
    >
      {isLoaded && (
         <Lightbox
         open={true}
         slides={slides}
         plugins={[Fullscreen, Thumbnails]}
         portal={{ root: containerRef.current }}
         render={{
           iconClose: () => null,
           slide: NextJsImage,
           thumbnail: NextJsImage,
         }}
         noScroll={{ disabled: true }}
       />
      )}
    </Box>
  );
}
