import(/* webpackMode: "eager", webpackExports: ["Box","Typography","Grid","Divider","Button"] */ "__barrel_optimize__?names=Box,Button,Divider,Grid,Typography!=!/vercel/path0/node_modules/@mui/material/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Box","Typography","Grid","Button"] */ "__barrel_optimize__?names=Box,Button,Grid,Typography!=!/vercel/path0/node_modules/@mui/material/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Box","Typography","Button"] */ "__barrel_optimize__?names=Box,Button,Typography!=!/vercel/path0/node_modules/@mui/material/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Container","Box","Typography"] */ "__barrel_optimize__?names=Box,Container,Typography!=!/vercel/path0/node_modules/@mui/material/index.js");
;
import(/* webpackMode: "eager" */ "__barrel_optimize__?names=Box,Container!=!/vercel/path0/node_modules/@mui/material/index.js");
;
import(/* webpackMode: "eager" */ "__barrel_optimize__?names=Box,Typography!=!/vercel/path0/node_modules/@mui/material/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Box"] */ "__barrel_optimize__?names=Box!=!/vercel/path0/node_modules/@mui/material/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/icons-material/KeyboardArrowLeft.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/icons-material/KeyboardArrowRightSharp.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/@mui/icons-material/StarRateRounded.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/legacy/image.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/sections/fashion/professional-home/client-timer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/sections/fashion/professional-home/deal-today-slider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/sections/fashion/professional-home/hero-slider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/sections/fashion/professional-home/popular-products-taps.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/sections/fashion/professional-home/popular-reviews-slider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/sections/home/home-best-sellers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/sections/home/home-categories.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/sections/home/home-hero.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/sections/home/home-most-popular.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/sections/home/home-offers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/sections/home/view/product-view.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/sections/home2/product-card.tsx");
