"use client";

import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useGetProducts } from "@/api/products";
import LoadingSpinner from "@/components/loading-spinner";
import { ProductProps } from "@/type/product";
import ProductCard from "../home2/product-card";
import { Company } from "@/type/company";
import { useTranslation } from "@/app/i18n/client";
import Link from "next/link";

export default function HomeBestSellers({
  company,
  lang,
}: {
  company: Company;
  lang: string;
}) {
  const { t } = useTranslation(lang, "product", {});

  //TODO: get producst where isMostSold = true
  const { products, productsLoading } = useGetProducts();

  const isArabic = lang == "ar";

  const [productsData, setProductsData] = useState<ProductProps[]>([]);

  useEffect(() => {
    if (products.length) {
      const newProducts = products.map((product: ProductProps) => ({
        ...product,
        name: isArabic
          ? product.arName || product.enName
          : product.enName || product.arName,
        subDescription: isArabic
          ? product.arSubDescription || product.enSubDescription
          : product.enSubDescription || product.arSubDescription,
        coverUrl: product.images[0],
      }));
      setProductsData(newProducts);
    }
  }, [isArabic, products]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          px: { xs: "16px", lg: "80px" },
          py: { xs: "14px", lg: "26px" },
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          background: "rgba(245, 245, 245, 0.5)",
        }}
      >
        <Typography
          sx={{
            color: "secondary.main",
            fontSize: { xs: "14px", lg: "24px" },
            fontWeight: "700",
          }}
        >
          {t("best-seller")}
        </Typography>
        <Link href={"/products?isMostSold=true"} legacyBehavior={true} >
          <Typography sx={{ color: "rgba(0, 0, 0, 0.4)", textDecoration: "underline", fontSize: { xs: "12px", lg: "16x" }, cursor: "pointer" }}>
            {t("view-more")}
          </Typography>
        </Link>
      </Box>

      <Box>
        {productsLoading ? (
          <LoadingSpinner />
        ) : (
          <Box
            sx={{
              mt: { xs: "24px", lg: "64px" },
              mb: "104px",
              px: { xs: "16px", lg: "50px" },
              pb: { lg: "40px" },
              display: { xs: "flex", lg: "grid" },
              flexWrap: "nowrap",
              gridTemplateColumns: "repeat(4, 1fr)",
              rowGap: "40px",
              columnGap: "16px",
              justifyContent: { xs: "flex-start", lg: "center" },
              overflowX: "auto",
            }}
          >
            {productsData.map((product: ProductProps) => (
              <ProductCard
                key={product.id}
                product={product}
                company={company}
                lang={lang}
              />
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
}
